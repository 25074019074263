.container-booking {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    width: 80%;
    margin: 0 auto;
    font-weight: 700;
}

.header-booking{
    max-width: 90%;
    margin: 10px 0;
    padding: 10px;
    text-align: left;
    color: rgb(172, 121, 27);
    background: rgb(26, 23, 23);
    border-radius: 10px;
}

.calender-booking {
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: center;
    background-color: #855806;
    border-radius: 10px;
    margin: 25px 0;
    width: 30%;
}



hr {
    width: 60%;
    border: 1px solid #855806
}

.input-field {
    /* text-align: left; */
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
}

.submit{
    /* border: none; */
    /* outline: none; */
    background: rgb(26, 23, 23);
    color: #855806;
    border-radius: 10px;
    max-width: 120px;
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    margin: 20px 0;
    font-weight: 800
}

.submit:hover{
    background: rgb(75, 63, 63);
    color: white
}

label{
    color: black;
    
}
input {
    border-radius: 5px;
    border-color: rgb(26, 23, 23);
    width: 100%;
    height: 25px;
}

textarea {
    border-radius: 5px;
    border-color: rgb(26, 23, 23);
    width: 100%;
    height: 50px;
}

form {
    width: 50%;
}

.homepage{
    background: rgb(26, 23, 23);
    color: rgb(172, 121, 27);
    border-radius: 10px;
    /* max-width: 120px; */
    /* width: auto; */
    height: 40px;
    cursor: pointer;
    margin: 20px 0;
    font-weight: 800
}

.homepage:hover{
    background: rgb(75, 63, 63);
    color: white 
}
 .error {
    color: rgba(88, 4, 4, 0.973);
    font-family:'Times New Roman', Times, serif;
 }

@media(max-width: 1200nfpx){
    .container-booking
    {
        width: 100%;
    }
    .calender-booking
    {
        width: 80%;
    }
    hr{
        width: 90%;
    }
    form {
        width: 80%;
    }
}
