.home-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(172, 121, 27);
    padding: 30px 20px;
    padding-bottom: 0;
}

.intro-left{
    flex: 0 0 50%;
    padding: 20px;
}

.intro-right{
    flex-grow: 1
}

.introduction{
    display: flex;
}

.intro-content{
    color: rgb(39, 22, 22);
    font-size: large;
    font-weight: 600;
}


@media(max-width: 969px){
    .introduction{
        flex-direction: column;
    }
 
    .img-overview{
        width: 100%;
    }
}