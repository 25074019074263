.menu {
    padding: 10px;
    background-color: rgb(26, 23, 23);
    /* position: relative; */
    /* display: block; */
    display: flex;
    align-items: center;
}

.logo{
    width: 150px;
    height: 150px;
}

.menuItem {
    margin: 1vw;
    text-decoration: none;
    color: rgb(172, 121, 27);
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.3rem;
    /* background-color: rgb(172, 121, 27); */
    padding: 10px;
    border-radius: 15px;
    font-weight: 800;
}


.menuItem:hover{
    background-color: rgb(88, 66, 24)
}

.menuItem-wrapper {
    display: flex;
    /* position: absolute;
    top: 50%; */
    padding-right: 10rem;
    /* display: none; */
}



.menuItem-logo {
    display: flex;
    flex-grow: 1;
    padding-left: 10rem;
}

.social-wrapper{
    background: rgb(172, 121, 27);
    display: flex;
    /* flex-direction: column; */
    justify-content: right;
    align-items: center;
    max-height: 50px;
}

.social-navbar{
    color: rgb(26, 23, 23);
    margin: 1vw;
}

.dropdown-menu-item{
    display: block;
    text-decoration: none;
    color: rgb(26, 23, 23);
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.3rem;
    font-weight: 800;
    margin-bottom: 3px;
    
}


.dropdown-menu-item:hover{
    background-color: rgb(88, 66, 24)
}

.dropdown{
    flex-direction: column;
    flex-grow: 1;
    display: none;
}

.dropdown-logo{
    /* flex-grow: 1; */
}

.dropdown-menu-icon{
    color: rgb(172, 121, 27);
    font-size: 1.5rem;
}

.dropdown-menu{
    padding: 1rem 0;
    background-color: rgb(172, 121, 27);
    /* text-align: left; */
    width: 100%;
    text-align: center;
}

.dropdown-footer{
    background-color: rgb(26, 23, 23);
    padding: 1rem 0;
}

@media(max-width: 969px){
    .menuItem-logo{
        display: none;
    }

    .menuItem-wrapper{
        display: none;
    }

    .dropdown{
        display: flex;
    }
}
